<template>
  <div class="contentDetail sharePageContent">
    <!-- <loading v-if="!detailAll" :iscomplete="!detailAll"></loading> -->
    <div v-if="detailAll">
      <div class="vedioStyleBox" v-if="detailAll && detailAll.video">
        <div class="coverImg" @click="vedioOpenPlay" v-if="thumbBlock">
          <img class="thumbImg" :src="detailAll.thumb.split(',')[0]" alt="" />
          <img class="vedioButtonSty" src="../assets/vedioSing.png" alt="" />
        </div>
        <video
          class="vedioContent"
          :src="detailAll.video"
          controls
          id="datailVedio"
        ></video>
      </div>
      <div class="contentAll" v-if="detailAll">
        <!-- <video :src="detailAll.video" controls  class="vedioContent"></video> -->
        <div class="contentTitltle">{{ detailAll.mainContributionTitle }}</div>
        <div class="F9efPzfcE5tLO0cygLjpo">
        <div class="_1rui3RTB5brFqhZq5nYylv"></div>
        <div class="Q46HXxwObg2rmy-Ms7f8I">{{ detailAll.source }}</div>
        <div class="Q46HXxwObg2rmy-Ms7f8I">{{ detailAll.releaseTime }}</div>
        <div class="_1rui3RTB5brFqhZq5nYylv"></div>
      </div>
      <div class="content" v-html="detailAll.content" id="contentde"></div>
      <div class="numberStyle" v-if="detailAll.content">
        阅读数：{{ number }}
      </div>
    </div>
    <div style="width: 100%; height: 8px; background: #f0f0f0"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// import newslist from "./cllComponents/newsListDetail";
import { Toast } from "vant";
Vue.use(Toast);
// import loading from "./cllComponents/loading";
import Homeapi from "@/api/home";
import ArticleApi from "@/api/article";

export default {
  components: {
    // loading,
    // newslist,
  },
  data() {
    return {
      detailAll: null, // 内容
      thumbBlock: true, // 封面图
      dataLIst: [], //推荐数据
      number: 0, //阅读数
      params: {
        contributionId: "",
        appName: this.appName,
        memberId: "",
      },
    };
  },
  mounted() {
    var id = window.location.href.split("&judge")[0].split("?id=")[1];
    this.datalinkDetail(id);
  },
  methods: {
    // 获取数据
    datalinkDetail(id) {
      let params = {
        id,
      };
      Homeapi.newsDratil(params).then((res) => {
        if (res.success) {
          console.log(res);
          this.detailAll = res.data;
          this.detailAll.content = this.detailAll.contributionContent;
          this.params.contributionId = this.detailAll.id;
          this.addReadNum();
          //   this.queryComment();
        }
      });
    },
    // 获取微信的token值
    wxinTokenLink() {
      this.$nextTick(() => {
        var news = this.shareData();
        this.$http
          .post(
            this.javalink +
              "usercenter/wxShare/sendMessageWx?url=" +
              encodeURIComponent(window.location.href)
          )
          .then((resp) => {
            // 通过config接口注入权限验证配置
            window.wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: resp.data.appid, // 必填，公众号的唯一标识
              timestamp: resp.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: resp.data.noncestr, // 必填，生成签名的随机串
              signature: resp.data.signature, // 必填，签名
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 必填，需要使用的JS接口列表
            });
            // 通过ready接口处理成功验证
            window.wx.ready(function () {
              //需在用户可能点击分享按钮前就先调用
              window.wx.updateAppMessageShareData({
                title: news.title, // 分享标题
                desc: news.describe, // 分享描述
                link: news.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: news.thumb, // 分享图标
                success: function () {
                  console.log("朋友");
                },
              }),
                window.wx.updateTimelineShareData({
                  title: news.title, // 分享标题
                  desc: news.describe, // 分享描述
                  link: news.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: news.thumb, // 分享图标
                  success: function () {
                    console.log("朋友圈");
                  },
                });
            });
          });
      });
    },
    // 分享
    shareData() {
      var describe = document
        .getElementById("contentdeCon")
        .innerText.replace(/&nbsp;/g, "")
        .slice(0, 50);
      var link;
      if (window.location.href.search("&judge") == -1) {
        if (this.detailAll.linkerapp) {
          link = this.detailAll.linkerapp; //链接
        } else {
          link =
            window.location.href.split("#/")[0] +
            "?#/sharePage?id=" +
            this.detailAll.id; //链接
        }
      } else {
        link =
          window.location.href.split("#/")[0] +
          "?#/sharePage?id=" +
          this.detailAll.medid_id +
          "&judge"; //链接
      }
      return {
        url: link,
        title: this.detailAll.title,
        thumb: this.detailAll.thumb.split(",")[0],
        describe: describe,
      };
      // this.shareContent({url: link,title:this.detailAll.title,thumb:this.detailAll.thumb.split(',')[0],describe:describe});
    },
    // 增加文章的阅读数
    addReadNum() {
      ArticleApi.addArticleNum(this.params).then((resp) => {
        this.number = resp.data;
      });
    },
    // 视频播放
    vedioOpenPlay() {
      var video = document.getElementById("datailVedio");
      if (video) {
        if (video.paused) {
          //如果已暂停则播放
          this.thumbBlock = false;
          video.play(); //暂停控制
        }
      }
    },
  },
};
</script>
<style>

.sharePageContent .content {
  text-align: justify;
  /* font-family: PingFang SC,Helvetica,Arial,\\5FAE\8F6F\96C5\9ED1,\\9ED1\4F53; */
  line-height: 0.62rem;
  font-size: 0.38rem;
}
.content * {
  max-width: 100% !important;
}
.sharePageContent .content img {
  height: auto !important;
}
.sharePageContent .content video {
  height: auto !important;
}
</style>
<style lang="less" scoped>
.topHead{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 5px 5px #DADADA;
  align-items: center;
  .topText{
    margin-left:10px ;
    span{
      line-height: 80px;
    }
    img{
      width: 35px;
      transform: translateY(11px);
    }

  }
  .back_button{
      margin-right:20px ;
      background-color: red;
      height:30px;
      border-radius: 15px;
      width: 1.6rem;
      font-size: 15px;
      color: #fff;
      text-align: center;
      line-height:30px ;
    }
}
.contentTitltle {
  font-size: 0.44rem;
  line-height: 0.64rem;
  // font-family: PingFang SC,Helvetica,Arial,\\5FAE\8F6F\96C5\9ED1,\\9ED1\4F53;
  color: #222;
  font-weight: 600;
  padding: 0.3rem 0;
  /* font-weight: 700; */
}
.F9efPzfcE5tLO0cygLjpo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.3rem;
  ._1rui3RTB5brFqhZq5nYylv {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
    -ms-flex: 1 1;
    flex: 1 1;
    height: 0;
    border-bottom: 1px solid #eaeaea;
  }
  .Q46HXxwObg2rmy-Ms7f8I {
    color: #9a9a9a;
    font-size: 0.26rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
}
.numberStyle {
  width: 100%;
  text-align: right;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  color: #999;
}
.contentDetail {
  width: 100%;
  height: 100%;
}
.contentAll {
  width: 100%;
  /* height: 100%; */
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
}
.content {
  width: 100%;
}
.vedioContent {
  width: 100%;
  height: 190px;
  object-fit: contain;
}
// .contentTitltle{
//     font-size: 20px;
//     /* font-weight: 700; */
// }
.sourceAndTime {
  width: 100%;
  font-size: 12px;
  line-height: 40px;
  color: #999;
}
.sourceFont {
  width: 78%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sourceAndTimeJava {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shareContent {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shareContent span {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #fff;
  margin-left: 2px;
}
.shareContent span:last-child {
  margin-right: 2px;
}

.vedioStyleBox {
  width: 100%;
  height: 190px;
  position: relative;
  /* margin-bottom: 12px; */
}
.coverImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
}
.thumbImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vedioButtonSty {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 1000;
}
.vedioStyle {
  width: 100%;
  height: 190px;
  object-fit: contain;
}
</style>